<template>
  <div>
    <div class="columns is-vcentered" v-if="hasPermissions">
      <div class="column">
        <a
          class="has-text-primary"
          v-tippy="{ placement: 'right' }"
          :title="instructions"
        >
          Customise your checks
          <span class="icon">
            <i class="fal fa-info-circle" />
          </span>
        </a>
      </div>
      <div class="column is-narrow">
        <AutoCheckBatchActionDropdown
          v-bind="batchActionProps"
          @toggleAction="toggleAction"
        />
      </div>
    </div>
    <div class="table-container">
      <table class="table is-size-7-touch is-striped is-fullwidth is-compact">
        <thead>
          <td v-for="(header, index) in headers" :key="index">
            <div v-if="!header.label">
              <div class="filter-menu-dropdown">
                <span class="radio-tick">
                  <checkbox v-model="allSelected" :value="index" />
                </span>
              </div>
            </div>
            <span class="heading is-marginless" v-else>
              {{ header.label }}
            </span>
          </td>
        </thead>
        <tbody>
          <tr
            v-for="(precheck, index) in batch"
            :key="index"
            :class="{ 'is-failed': precheck.failed }"
          >
            <td v-if="hasPermissions">
              <div class="filter-menu-dropdown">
                <span class="radio-tick">
                  <checkbox
                    :disabled="precheck.failed || !precheck.mileage"
                    v-model="selectAll[index]"
                  />
                </span>
              </div>
            </td>
            <td>
              <div class="field has-addons" v-if="precheck.vrm">
                <div class="control">
                  <span
                    class="button is-small"
                    :title="tooltip(precheck)"
                    v-tippy="{ placement: 'right', trigger: 'click' }"
                  >
                    <span class="icon has-text-info">
                      <i class="fal fa-eye" />
                    </span>
                  </span>
                </div>
                <div class="control">
                  <span class="button is-static is-size-7">
                    {{ precheck.vrm }}
                  </span>
                </div>
              </div>
              <span v-else class="has-text-grey-light">N/A</span>
            </td>
            <td>
              <span v-if="precheck.mileage"
                >{{ number(precheck.mileage.value) }}
                <a
                  v-tippy="{ placement: 'right' }"
                  v-if="precheck.mileage.estimate"
                  class="icon is-small is-size-7 has-text-primary"
                  title="Estimate"
                >
                  <i class="far fa-tilde" />
                </a>
              </span>
              <span v-else class="has-text-grey-light">
                <span> N/A</span>
                <a
                  class="icon is-small has-text-primary"
                  v-if="!precheck.failed"
                  v-tippy="{ placement: 'right' }"
                  title="Mileage could not be estimated for this vehicle"
                >
                  <i class="fal fa-info-circle" />
                </a>
              </span>
            </td>
            <td v-for="(option, index2) in valuations" :key="index2">
              <div class="columns is-mobile filter-menu-dropdown">
                <div class="column">
                  <span class="radio-tick">
                    <checkbox
                      v-if="precheck.failed || !precheck.mileage"
                      disabled
                      v-tippy="{ placement: 'left' }"
                      title="Valuations are not possible without
                    a mileage"
                    />
                    <checkbox
                      v-else-if="option.id === 'valuationCap'"
                      v-model="request[index][option.id]"
                    />
                    <checkbox v-else v-model="request[index][option.id]" />
                  </span>
                </div>
              </div>
            </td>
            <td v-if="$experian && permissions.dataGuarantee">
              <div class="field has-addons">
                <div class="control" v-for="term in dgTerms" :key="term">
                  <button
                    :disabled="precheck.failed"
                    :class="{
                      'is-success': request[index].dgTerm === term
                    }"
                    class="button is-small"
                    @click="request[index].dgTerm = term"
                  >
                    {{ term }}
                  </button>
                </div>
              </div>
            </td>
            <td v-if="$experian && permissions.dataGuarantee">
              <div class="field has-addons">
                <div
                  class="control"
                  v-for="level in dgLevels"
                  :key="level.value"
                >
                  <button
                    :disabled="precheck.failed"
                    :class="{
                      'is-success': request[index].dgLevel === level.value
                    }"
                    class="button is-small"
                    @click="request[index].dgLevel = level.value"
                  >
                    {{ level.label }}
                  </button>
                </div>
              </div>
            </td>
            <td v-if="permissions.mileageData && $experian">
              <div class="columns is-mobile filter-menu-dropdown">
                <div class="column">
                  <span class="radio-tick">
                    <checkbox
                      v-model="request[index].mileageData"
                      :disabled="precheck.failed"
                    />
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="buttons is-right" v-if="!failedAllPrechecks">
      <button class="button is-text" @click="$emit('cancel')">Cancel</button>
      <button
        class="button is-rounded is-soft"
        :class="{ 'is-loading': $wait.is('batchAutoCheck') }"
        :disabled="$wait.is('batchAutoCheck')"
        @click="batchAutoCheck"
      >
        Submit
      </button>
    </div>
    <div v-else class="columns is-vcentered">
      <div class="column">
        <p class="help has-text-info has-text-right">
          None of the requested vehicles were found. Go back to try again.
        </p>
      </div>
      <div class="column is-narrow">
        <button class="button is-text" @click="$emit('cancel')">Go back</button>
      </div>
    </div>
  </div>
</template>

<script>
import { number } from 'core/utils/format'
import * as autocheck from 'modules/autocheck/services'
import { mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import * as tooltips from '../utils/autocheck-batch.tooltips'
export default {
  name: 'AutoCheckBatchOptions',
  components: {
    AutoCheckBatchActionDropdown: () => import('./AutoCheckBatchActionDropdown')
  },
  props: {
    batch: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    request: null,
    allSelected: false,
    selectAll: []
  }),
  watch: {
    allSelected: 'toggleAll'
  },
  created() {
    this.selectAll = Array.from({ length: this.batch.length }, () => false)
    this.request = this.batch.map(precheck => {
      return {
        vrm: precheck.vrm,
        vin: precheck.vin,
        mileage: precheck.mileage ? precheck.mileage.value : null,
        mileageEstimated: precheck.mileage ? precheck.mileage.estimate : false,
        valuationCap: false,
        valuationGlass: false,
        valuationCazana:
          this.hasCazanaValues && !precheck.failed && precheck.mileage
            ? true
            : false,
        dgTerm: this.$experian && !precheck.failed ? 12 : null,
        dgLevel: this.$experian && !precheck.failed ? 10000 : null,
        mileageData: false,
        failed: precheck.failed
      }
    })
  },
  computed: {
    ...mapGetters('auth', ['user', 'permissions', 'hasCazanaValues']),
    dgTerms: () => [12, 24, 36],
    dgLevels: () => [
      { value: 10000, label: 10 },
      { value: 20000, label: 20 },
      { value: 30000, label: 30 },
      { value: 40000, label: 40 },
      { value: 50000, label: 50 }
    ],
    batchActionProps() {
      return {
        selectAll: this.selectAll,
        permissions: this.permissions,
        valuations: this.valuations,
        hasCazanaValues: this.hasCazanaValues,
        dgLevels: this.dgLevels,
        dgTerms: this.dgTerms
      }
    },
    hasPermissions() {
      return (
        this.hasCazanaValues ||
        this.permissions.capValues ||
        this.permissions.glassValues ||
        this.permissions.dataGuarantee ||
        this.permissions.mileageData
      )
    },
    valuations() {
      const valuations = []
      const { hasCazanaValues, permissions } = this

      if (hasCazanaValues) {
        valuations.push({
          id: 'valuationCazana',
          label: 'cazana',
          toggleable: true
        })
      }

      if (this.$experian) {
        if (permissions.capValues) {
          valuations.push({
            id: 'valuationCap',
            label: 'cap',
            toggleable: true
          })
        }

        if (permissions.glassValues) {
          valuations.push({
            id: 'valuationGlass',
            label: 'glass',
            toggleable: true
          })
        }
      }

      return valuations
    },
    headers() {
      if (!this.hasPermissions) return [{ label: 'vrm' }, { label: 'mileage' }]

      const headers = [
        { label: '' },
        { label: 'vrm' },
        { label: 'mileage' },
        ...this.valuations
      ]
      if (this.$experian) {
        const dg = [{ label: 'Guarantee term' }, { label: 'Guarantee level' }]
        if (this.permissions.dataGuarantee) headers.push(...dg)
        if (this.permissions.mileageData)
          headers.push({ label: 'Mileage data' })
      }

      return headers
    },
    instructions() {
      const { permissions, valuations, $experian } = this
      const content = `
          ${tooltips.intro}
          ${(valuations.length && tooltips.valuation) || ''}
          ${($experian && permissions.dataGuarantee && tooltips.dg) || ''}
          ${($experian && permissions.mileageData && tooltips.mileageData) ||
            ''}
      `
      return tooltips.instructions(content)
    },
    failedAllPrechecks() {
      return this.batch.every(({ failed }) => failed)
    }
  },
  methods: {
    number,
    toggleAction(value, prop) {
      const action = value ? 'select' : 'deselect'
      this.$mxp.track(
        `autocheck_batch_options_batch_${action}_${snakeCase(prop)}`
      )

      this.selectAll.forEach((v, i) => {
        if (v && !this.request[i].failed) {
          if (!prop.includes('valuation')) {
            this.request[i][prop] = value
          } else if (this.request[i].mileage) {
            this.request[i][prop] = value
          }
        }
      })
    },
    toggleAll() {
      this.selectAll = this.selectAll.map((_, i) => {
        if (!this.batch[i].failed) {
          return this.allSelected
        }
      })
    },
    tooltip(precheck) {
      if (precheck.failed) {
        return 'N/A'
      }

      const description = this.description(precheck)
      return tooltips.precheck(precheck, description)
    },
    description(vehicle) {
      return autocheck.buildPreDescription(vehicle)
    },
    async batchAutoCheck() {
      try {
        this.$wait.start('batchAutoCheck')
        const batch = this.request.filter(o => !o.failed)
        batch.forEach(o => delete o.failed)

        if (!batch.length)
          return this.$notify({ group: 'danger', text: 'No vehicles found' })

        const { email } = this.user
        const { uuid } = await autocheck.batchAutoCheck({ batch, email })
        this.$router.push({ name: 'autocheck-summary', params: { uuid } })
        this.$notify({ group: 'success', text: 'Batch completed' })
      } catch (err) {
        this.$notify({
          group: 'danger',
          text: 'AutoCheck failed for one or more requests'
        })
      } finally {
        this.$wait.end('batchAutoCheck')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.table
  &.is-compact
    td
      width: 1%
      white-space: nowrap
.is-failed
  opacity: .5
  pointer-events: none
</style>
