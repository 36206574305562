export const mileageData = `
  <p class="heading has-text-weight-semibold">
    <span class="icon is-small has-text-info">
      <i class="fad fa-tachometer-alt-slow"></i>
    </span>
    <span>Request mileage data</span>
  </p>
`

export const dg = `
  <p class="heading has-text-weight-semibold">
    <span class="icon is-small has-text-info">
      <i class="fad fa-calendar-alt"></i>
    </span>
    <span>Choose Guarantee term (months)</span>
  </p>
  <p class="heading has-text-weight-semibold">
    <span class="icon is-small has-text-info">
      <i class="fad fa-shield"></i>
    </span>
    <span>Choose Guarantee level (thousands of £)</span>
  </p>
`

export const valuation = `
  <p class="heading has-text-weight-semibold">
    <span class="icon is-small has-text-info">
      <i class="fad fa-money-bill"></i>
    </span>
    <span>Choose valuation options</span>
  </p>
`

export const intro = `
  <p class="heading has-text-weight-semibold is-7">
    <span class="icon is-small has-text-info">
      <i class="fad fa-list"></i>
    </span>
    <span>Select checks using the checkboxes</span>
  </p>
  <p class="heading has-text-weight-semibold">
    <span class="icon is-small has-text-info">
      <i class="fad fa-toggle-off"></i>
    </span>
    <span>Customise selection via the actions button</span>
  </p>
`

export const precheck = (precheck, description) => `
  <div class="has-text-left">
    <p class="heading">
      <span class="has-text-weight-semibold">VRM</span>
      <span>${description}</span>
    </p>
    <p class="heading">
      <span class="has-text-weight-semibold">VIN</span>
      <span>${precheck.vin}</span>
    </p>
  </div>
`

export const instructions = content => `
  <div class="has-text-left content">
    ${content}
  </div>
`
